import Image from 'next/image'
import Link from 'next/link'

import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ComponentPrincipalSlidesEntity } from '@/types/strapi/Components.types'

interface HomePrincipalSlide {
  slides: ComponentPrincipalSlidesEntity[]
}

const Slider = ({ slides }: HomePrincipalSlide) => {
  if (slides.length <= 0) return null

  return (
    <div className="relative w-full lg:container md:mx-auto">
      <Swiper
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        autoHeight={true}
        spaceBetween={10}
        navigation={false}
        slidesPerView={1}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          el: '.swiper-pagination-slider',
        }}
        loop={true}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {slides.map(
          (
            {
              href,
              image,
              description,
              imageMobile,
              aspectRatioDesktop,
              aspectRatioMobile,
            },
            key
          ) => (
            <SwiperSlide key={key}>
              <Link href={href} title={description}>
                <div className="p-1.5 md:p-2">
                  <div
                    className="shadow-card relative hidden w-full cursor-pointer rounded-xl md:block"
                    style={{
                      aspectRatio: aspectRatioDesktop,
                    }}
                  >
                    <Image
                      fill
                      className="rounded-xl"
                      priority
                      src={image.data.attributes.url || ''}
                      alt={description || ''}
                    />
                  </div>
                  <div
                    className="shadow-card relative block w-full cursor-pointer rounded-xl md:hidden"
                    style={{
                      aspectRatio: aspectRatioMobile,
                    }}
                  >
                    <Image
                      fill
                      className="rounded-xl"
                      priority
                      src={imageMobile.data.attributes.url || ''}
                      alt={description || ''}
                    />
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          )
        )}
      </Swiper>
      <div
        className="swiper-pagination swiper-pagination-slider"
        style={{
          bottom: '10px !important',
        }}
      />
    </div>
  )
}

export default Slider
