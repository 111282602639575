/* eslint-disable tailwindcss/no-custom-classname */
import { useState } from 'react'

import clsx from 'clsx'

import IconServices from './IconServices'
import { Colors, Sizes } from '@/components/types'

export interface ServiceCard {
  circleColor?: Colors | string
  iconSize?: Sizes | string
  circleSize?: Sizes | string
  icon: string
  iconPosition?: 'left' | 'center' | string
  title: string
  fullwidth?: boolean
  description?: string
}

const ServiceCard = ({
  circleColor = 'lipa',
  iconSize = 'md',
  circleSize = 'md',
  icon,
  iconPosition = 'center',
  title,
  fullwidth,
  description,
}: ServiceCard) => {
  const [pressed, setPressed] = useState(false)

  const handleButtonClick = () => {
    setPressed(true)
    setTimeout(() => setPressed(false), 300)
  }

  return (
    <div
      onClick={handleButtonClick}
      className={clsx(
        'h-full min-w-[72px] transition duration-500 ease-in-out',
        pressed ? 'scale-95' : 'scale-100'
      )}
    >
      <div
        className={clsx(
          fullwidth && 'w-full',
          'card shadow-card h-full cursor-pointer'
        )}
      >
        <div
          className={clsx(
            'flex gap-3',
            `service-card-icon-posicion-${iconPosition}`
          )}
        >
          <div
            className={clsx(
              `service-card-circle-${circleColor}`,
              `service-card-circle-${circleSize}`,
              'flex items-center justify-center rounded-full'
            )}
          >
            <div className={clsx(`service-card-icon-${iconSize}`)}>
              <IconServices iconName={icon} />
            </div>
          </div>
          {description ? (
            <>
              <span className="text-xs font-bold">{title}</span>
              <span className="text-sm">{description}</span>
            </>
          ) : (
            <span className="text-center text-sm font-medium md:text-base">
              {title}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
